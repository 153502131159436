import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import { Box } from 'theme-ui';
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import CauseWater4AllFeatures from 'components/CauseWater4AllFeatures';
import CauseWater4AllBuildYourOwnWell from 'components/CauseWater4AllBuildYourOwnWell';
import featureWater from 'images/feat-white-water.svg';
import featureMan from 'images/feat-white-man.svg';
export const pageQuery = graphql`
  query WaterForAll {
    heroImage: file(relativePath: {eq: "project-w4all-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ...WaterForAllBuildYourOwnWell
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Water For All: The best form of charity is to give someone water (to drink)" description="Today, more than 600 million people do not have access to drinking water. Water is not only difficult to obtain, but often it is also contaminated: 80% of diseases in the world are related to the consumption of non-potable water or to poor sanitation, causing the death of millions of people every year around the world." mdxType="SEO" />
    <CauseHero purpose="water-for-all" title="Water For All" text='"The best form of charity is to give someone water (to drink)"' footer="Prophet Muhammad ﷺ" data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <CauseWater4AllFeatures features={[{
      icon: featureWater,
      numbers: '200+',
      text: 'Water Projects completed'
    }, {
      icon: featureMan,
      numbers: '30,000+',
      text: 'Beneficiaries'
    }]} mdxType="CauseWater4AllFeatures" />
    <Box className="p-wrapper" sx={{
      '&.p-wrapper': {
        marginBottom: [70,, 133]
      }
    }} mdxType="Box">
      <p>{`Today, more than 600 million people do not have access to drinking water. Water is not only difficult to obtain,
but often it is also contaminated: 80% of diseases in the world are related to the consumption of non-potable water
or to poor sanitation, causing the death of millions of people every year around the world.`}</p>
      <p>{`Since we founded Ummaty, Water4all has been one of the projects we are most proud of. We work everyday to provide
safe drinking water to populations around the world. Alhamdoulillah we carry out dozens of water projects every year
and have developed an important expertise in this domain. Our water projects are of different nature: from simple surface
wells to deep wells and where feasible, we provide the complete infrastructure including pumps, water tanks and pipes to
provide drinking water directly to homes and schools and other facilities.`}</p>
    </Box>
    <CauseWater4AllBuildYourOwnWell data={props.data} title="Build your own Well" subtitle="For yourself or for a loved one" content={`
  <ul>
    <li>
      Ummaty also offers you the opportunity to build your own surface
      well and to get the&nbsp;
      <b>entire rewards for this sadaqa jariyah</b>
      whenever a child, a woman or man from these communities would be
      drinking.
    </li>
    <li>
      For a cost starting from <b>$180 USD</b> we will dig a surface
      well, install a pump and have a plate with your name. You could
      also name the well and dedicate this sadaqa to a person you
      love.
    </li>
    <li>
      After the project completion, Ummaty team will provide you with
      a <b>detailed report</b>, including well information,&nbsp;
      <b>photos and well inauguration video links.</b>
    </li>
    <li>
      Water4all Build your own well: <b>the best gift</b> you could
      offer yourself or to your loved ones, alive or deceased.
    </li>
  </ul>
  `} buttonText="Build my own Well" mdxType="CauseWater4AllBuildYourOwnWell" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      